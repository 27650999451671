<template>
  <div>
    <v-row>
      <v-col
        v-if="$store.getters['post/HasPageLoaded'](1)"
        md="4"
        sm="4"
        cols="12"
        class="pl-0 pr-0 mr-0 img-sidebar-block d-md-none d-lg-flex"
      >
        <v-img
          :src="article.featuredImage"
          class="img-sidebar"
        >
          <div class="mt-6"></div>
          <div class="section-title-home ml-10 mb-4">
            Recent posts
          </div>

          <div
            v-for="post in $store.getters['post/GetPosts'](1).data.filter(x => x.id !== selectedId).slice(0, 5)"
            :key="post.id + 'post'"
            class="news-content-sidebar"
            gradient="to top,  rgba(20, 20, 23,.9), rgba(20, 20, 23,.33)"

            @click="changeArticle(post)"
          >
            {{ unixToFormattedTime(article.timestamp) }}<br>
            <div
              class="v-avatar-group"
              :class="rootThemeClasses"
            >
              <a
                class="news-link"
                href="#"
              >
                {{ post.title }}</a>
            </div>
          </div>
        </v-img>
      </v-col>
      <v-col
        cols="12"
        md="6"
        sm="12"
        class="ml-0 pl-0"
      >
        <div>
          <div v-if="article">
            <v-card class="article">
              <p class="post-title ml-5 mb-5 pt-6">
                {{ article.title }}
              </p>
              <v-card class="author-card">
                <v-list class="author-card">
                  <template>
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-img :src="article.authorAvatar"></v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title class="mt-0 date-font">
                          {{ unixToFormattedTime(article.timestamp) }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <span class="text--primary">
                            {{ article.authorNickname }}
                          </span>
                          &mdash; {{ article.authorRoleLabel }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </v-card>
              <v-card-text
                class="news-wrap news-font"
                v-html="article.content"
              >
              </v-card-text>
            </v-card>
          </div>
          <div
            v-else-if="article == null"
            class="text-center mt-10"
          >
            <v-progress-circular
              :size="100"
              indeterminate
              color="primary"
            >
            </v-progress-circular>
          </div>
          <div v-else>
            WUT? Not working, please report this to administrators. We're really sorry, that you couldn't read the post you wanted to.
          </div>
        </div>
      </v-col>
      <v-col

        md="1"
        sm="4"
        cols="12"
        class="pl-0 pr-0 mr-0 socials"
      >
        <v-btn
          class="like-icon"
          @click="likes++"
        >
          <v-icon class="like-icon">
            {{ icons.mdiFire }}
          </v-icon>
          {{ likes }}
        </v-btn>
        <v-btn
          class="sad-icon mt-2"
          @click="dislikes++"
        >
          <v-icon class="sad-icon">
            {{ icons.mdiEmoticonSadOutline }}
          </v-icon>
          {{ dislikes }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { unixToFormattedTime } from '@core/utils/'
import { mdiFire, mdiEmoticonSadOutline } from '@mdi/js'
import axiosClient from '@/plugins/axios'

export default {
  setup() {
    const likes = 0
    const dislikes = 0

    return {
      likes,
      dislikes,
      icons: {
        mdiFire,
        mdiEmoticonSadOutline,
      },
    }
  },
  data() {
    return {
      article: null,
      selectedId: this.$route.params.id,
    }
  },
  async mounted() {
    if (this.selectedId) {
      const response = await axiosClient.get(`${this.$API}/article/${this.selectedId}`)
      if (!response.data.title) return
      this.article = response.data
    }
  },
  methods: {
    unixToFormattedTime,
    async changeArticle(article) {
      this.article = null
      this.selectedId = article.id
      const response = await axiosClient.get(`${this.$API}/article/${this.selectedId}`)
      if (!response.data.title) return
      this.article = response.data
    },
  },
}
</script>
